import React, { useCallback, useEffect, useState } from 'react';
import { Page, Input, ToggleSwitch, Text, WixDesignSystemProvider, Card, FormField, Button, CloseButton, FloatingNotification, Dropdown, listItemSelectBuilder, Loader, Thumbnail, Layout, Cell, Image, EmptyState, TextButton, Box, Table, TableToolbar, Badge, TableActionCell, Notification, Tooltip, BadgeSelect, SkeletonGroup, SkeletonRectangle, Modal, AnnouncementModalLayout, InputArea } from '@wix/design-system';
import "@wix/design-system/styles.global.css";
import { ReactTagManager } from 'react-gtm-ts';
import './App.css';
import * as Icons from "@wix/wix-ui-icons-common";
// >>>
import type { JsonGroup, Config, ImmutableTree, BuilderProps } from '@react-awesome-query-builder/antd'; // for TS example
import { Query, Builder, Utils as QbUtils } from '@react-awesome-query-builder/antd';
import { AntdConfig, AntdWidgets } from '@react-awesome-query-builder/antd';
import '@react-awesome-query-builder/antd/css/styles.css';
const InitialConfig = AntdConfig;
// <<<

const Templates = {
  additionalFees: {
    name: 'My rule',
    type: "additional-fees",
    isEnabled: true,
    input: {},
    output: [{
      code: "HANDLING_FEE",
      name: "Handling fee",
      price: "5.00",
      taxDetails: {
        taxable: false
        // taxGroupId: "1"
      }
    }]
  },
  shippingRates: {
    name: 'My shipping options rule',
    type: "shipping-rates",
    isEnabled: true,
    input: {},
    output: [{
      code: "EXPRESS_DELIVERY",
      title: "Express Delivery",
      logistics: {
        deliveryTime: "Same Day Guaranteed",
        instructions: "We will deliver your order today, guaranteed.",
        // pickupDetails: {
        //   address: {
        //     country: "US",
        //     subdivision: "NY",
        //     city: "Test City",
        //     postalCode: "10000",
        //     addressLine: "Demo Address 1",
        //     addressLine2: "Demo Address 2"
        //   },
        //   pickupMethod: "STORE_PICKUP" // "PICKUP_POINT"
        // }
      },
      cost: {
        price: "5.00",
        currency: "USD",
        // additionalCharges: [{
        //   type: "HANDLING_FEE",
        //   details: "Handling fee",
        //   price: "5.00"
        // }]
      }
    }]
  },
  validations: {
    name: 'My validations rule',
    type: "validations",
    isEnabled: true,
    input: {},
    output: [{
      "severity": "ERROR",
      "target": {
        "other": {
          "name": "OTHER_DEFAULT"
        }
      },
      "description": "Can't apply coupon because minimum cart value has not been reached.",
    }]
  },
}

// You can load query value from your backend storage (for saving see `Query.onChange()`)
const queryValue: JsonGroup = { id: QbUtils.uuid(), type: "group" };

const tagManagerArgs = {
  code: 'GTM-WZQPMC7'
}

const Fields = {
  'additional-fees': {
    lineItems: {
      label: "Line items",
      type: "!group",
      mode: "array",
      // @ts-ignore
      fieldSettings: {

      },
      subfields: {
        id: {
          label: "Line item ID",
          type: "text",
          valueSources: ["value"],
        },
        quantity: {
          label: "Quantity",
          type: "number",
          valueSources: ["value"],
          fieldSettings: {
            min: 1,
            max: 100000
          },
        },
        catalogReference: {
          label: "Catalog reference",
          type: "!struct",
          subfields: {
            catalogItemId: {
              label: "Catalog item ID",
              type: "text",
              valueSources: ["value"],
            },
            appId: {
              label: "ID of the catalog app",
              type: "text",
              valueSources: ["value"],
            },
            // options: {
            //   label: "Options",
            //   type: "!group",
            // }
          }
        },
        productName: {
          label: "Item name",
          type: "text",
          valueSources: ["value"],
        },
        price: {
          label: "Price for a single item",
          type: "number", // converted
          valueSources: ["value"],
          preferWidgets: ['number'],
        },
        physicalProperties: {
          label: "Physical properties of the item",
          type: "!struct",
          subfields: {
            weight: {
              label: "Line item weight",
              type: "number",
              valueSources: ["value"],
            },
            sku: {
              label: "Stock-keeping unit",
              type: "text",
              valueSources: ["value"],
            },
            shippable: {
              label: "Shippable?",
              type: "boolean",
              valueSources: ["value"],
            },
          }
        },
        depositAmount: {
          label: "Partial payment to be paid upfront during the checkout",
          type: "text",
          valueSources: ["value"],
        }
      }
    },
    shippingAddress: {
      label: "Shipping address",
      type: "!struct",
      subfields: {
        addressLine1: {
          label: "Address Line 1",
          type: "text",
          valueSources: ["value"],
        },
        addressLine2: {
          label: "Address Line 2",
          type: "text",
          valueSources: ["value"],
        },
        city: {
          label: "City name",
          type: "text",
          valueSources: ["value"],
        },
        subdivision: {
          label: "Code for a subdivision (such as state, prefecture, or province) in ISO 3166-2 format",
          type: "text",
          valueSources: ["value"],
        },
        country: {
          label: "Two-letter country code in ISO-3166 alpha-2 format",
          type: "text",
          valueSources: ["value"],
        },
        postalCode: {
          label: "Postal or zip code",
          type: "text",
          valueSources: ["value"],
        },
        streetAddress: {
          label: "Street address",
          type: "!group", // enforce
          subfields: {
            number: {
              label: "Street number",
              type: "text",
              valueSources: ["value"],
            },
            name: {
              label: "Street name",
              type: "text",
              valueSources: ["value"],
            },
          }
        }
      }
    },
    buyerDetails: {
      label: "Information about the buyer, such as contact details",
      type: "!group", // enforce
      subfields: {
        contactDetails: {
          label: "Contact details",
          type: "!group", // enforce
          subfields: {
            firstName: {
              label: "Contact's first name",
              type: "text",
              valueSources: ["value"],
            },
            lastName: {
              label: "Contact's last name",
              type: "text",
              valueSources: ["value"],
            },
            phone: {
              label: "Contact's phone number",
              type: "text",
              valueSources: ["value"],
            },
            company: {
              label: "Contact's company name",
              type: "text",
              valueSources: ["value"],
            },
            email: {
              label: "Email associated with the address",
              type: "text",
              valueSources: ["value"],
            },
            vatId: {
              label: "Tax info (Brazil)",
              type: "!struct",
              subfields: {
                id: {
                  label: "Customer's tax ID",
                  type: "text",
                  valueSources: ["value"],
                },
                type: {
                  label: "Tax type",
                  type: "text",
                  listValues: ['UNSPECIFIED', 'CPF', 'CNPJ'].map(v => ({ value: v, title: v })),
                  valueSources: ["value"],
                },
              }
            }
          }
        }
      }
    },
    shippingInfo: {
      label: "Shipping information",
      type: "!group", // enforce
      subfields: {
        selectedCarrierServiceOption: {
          label: "Selected carrier shipping option",
          type: "!struct",
          subfields: {
            code: {
              label: "Unique identifier of selected option",
              type: "text",
              valueSources: ["value"],
            },
            otherCharges: {
              label: "Other charges",
              type: "!group",
              subfields: {
                type: {
                  label: "Type of charge",
                  type: "text",
                  listValues: ['HANDLING_FEE', 'INSURANCE'].map(v => ({ value: v, title: v })),
                  valueSources: ["value"],
                },
                details: {
                  label: "Details of the charge",
                  type: "text",
                  valueSources: ["value"],
                },
                cost: {
                  label: "Cost",
                  type: "!struct",
                  subfields: {
                    price: {
                      label: "Price",
                      type: "number", // converted
                      valueSources: ["value"],
                      preferWidgets: ['number'],
                    },
                    totalDiscount: {
                      label: "Total shipping discount",
                      type: "number", // converted
                      valueSources: ["value"],
                      preferWidgets: ['number'],
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    ecomId: {
      label: "Wix eCommerce ID",
      type: "text",
      valueSources: ["value"],
    }
  },
  'shipping-rates': {
    lineItems: {
      label: "Line items",
      type: "!group",
      mode: "array",
      // @ts-ignore
      fieldSettings: {

      },
      subfields: {
        name: {
          label: "Item name",
          type: "text",
          valueSources: ["value"],
        },
        quantity: {
          label: "The number of items ordered",
          type: "number",
          valueSources: ["value"],
          preferWidgets: ['number'],
        },
        catalogReference: {
          label: "Reference to the item's origin catalog",
          type: "!struct",
          subfields: {
            catalogItemId: {
              label: "ID of the item within its Wix or 3rd-party catalog",
              type: "text",
              valueSources: ["value"],
            },
            appId: {
              label: "ID of the catalog app",
              type: "text",
              valueSources: ["value"],
            },
            // options: {

            // }
          },
        },
        physicalProperties: {
          label: "Physical properties of the item",
          type: "!struct",
          subfields: {
            weight: {
              label: "Line item weight",
              type: 'number',
              valueSources: ["value"],
              preferWidgets: ['number'],
            },
            sku: {
              label: "Stock-keeping unit",
              type: "text",
              valueSources: ["value"],
            },
            shippable: {
              label: "Whether this line item is shippable",
              type: "boolean",
              valueSources: ["value"],
              preferWidgets: ['boolean'],
            },
          }
        },
        price: {
          label: "Price of a single item after discounts",
          type: "number", // converted
          valueSources: ["value"],
          preferWidgets: ['number'],
        },
        totalPriceBeforeDiscount: {
          label: "Total line item price before discounts",
          type: "number", // converted
          valueSources: ["value"],
          preferWidgets: ['number'],
        },
        priceBeforeDiscount: {
          label: "Price of a single item before discounts",
          type: "number", // converted
          valueSources: ["value"],
          preferWidgets: ['number'],
        },
        totalPrice: {
          label: "Total line item price after discounts",
          type: "number", // converted
          valueSources: ["value"],
          preferWidgets: ['number'],
        },
      }
    },
    shippingDestination: {
      label: "Address to ship to",
      type: "!struct",
      subfields: {
        country: {
          label: "Country code",
          type: "text",
          valueSources: ["value"],
        },
        city: {
          label: "City name",
          type: "text",
          valueSources: ["value"],
        },
        postalCode: {
          label: "Zip/postal code",
          type: "text",
          valueSources: ["value"],
        },
        streetAddress: {
          label: "Street name and number",
          type: "!struct",
          subfields: {
            number: {
              label: "Street number",
              type: "text",
              valueSources: ["value"],
            },
            name: {
              label: "Street name",
              type: "text",
              valueSources: ["value"],
            },
          }
        },
        addressLine: {
          label: "Main address line, usually street and number as free text",
          type: "text",
          valueSources: ["value"],
        },
        addressLine2: {
          label: "Free text providing more detailed address info",
          type: "text",
          valueSources: ["value"],
        },
      }
    },
    // shippingOrigin: {

    // }
    buyerContactDetails: {
      label: "Buyer's contact details",
      type: "!struct",
      subfields: {
        firstName: {
          label: "Contact's first name",
          type: "text",
          valueSources: ["value"],
        },
        lastName: {
          label: "Contact's last name",
          type: "text",
          valueSources: ["value"],
        },
        phone: {
          label: "Contact's phone number",
          type: "text",
          valueSources: ["value"],
        },
        company: {
          label: "Contact's company name",
          type: "text",
          valueSources: ["value"],
        },
        email: {
          label: "Email associated with the address",
          type: "text",
          valueSources: ["value"],
        },
        vatId: {
          label: "Tax info (Brazil)",
          type: "!struct",
          subfields: {
            id: {
              label: "Customer's tax ID",
              type: "text",
              valueSources: ["value"],
            },
            type: {
              label: "Tax type",
              type: "text",
              listValues: ['UNSPECIFIED', 'CPF', 'CNPJ'].map(v => ({ value: v, title: v })),
              valueSources: ["value"],
            },
          }
        },
      }
    },
    weightUnit: {
      label: "Weight unit",
      type: "text",
      listValues: ['UNSPECIFIED_WEIGHT_UNIT', 'KG', 'LB'].map(v => ({ value: v, title: v })),
      valueSources: ["value"],
    },
    taxIncludedInPrices: {
      label: "Whether tax is included in the items' prices",
      type: "boolean",
      valueSources: ["value"],
    }
  },
  'validations': {
    sourceInfo: {
      label: "Information about the source of the request",
      type: "!struct",
      subfields: {
        source: {
          label: "Source of the request",
          type: "text",
          listValues: ['OTHER', 'CART', 'CHECKOUT'].map(v => ({ value: v, title: v })),
          valueSources: ["value"],
        },
        ecomId: {
          label: "Wix eCommerce ID: Deprecated. Please use purchaseFlowId.",
          type: "text",
          valueSources: ["value"],
        },
        purchaseFlowId: {
          label: "Purchase Flow ID: Persistent ID that correlates between the various eCommerce elements: cart, checkout, and order.",
          type: "text",
          valueSources: ["value"],
        }
      }
    },
    validationInfo: {
      label: "Information to validate",
      type: "!struct",
      subfields: {
        buyerDetails: {
          label: "Information about the buyer, such as contact details",
          type: "!struct", // enforce
          subfields: {
            email: {
              label: "Email associated with the address",
              type: "text",
              valueSources: ["value"],
            },
            contactId: {
              label: "Contact ID",
              type: "text",
              valueSources: ["value"],
            }
          }
        },
        lineItems: {
          label: "Line items",
          type: "!group",
          mode: "array",
          // @ts-ignore
          fieldSettings: {
          },
          subfields: {
            id: {
              label: "Line item ID",
              type: "text",
              valueSources: ["value"],
            },
            quantity: {
              label: "Quantity",
              type: "number",
              valueSources: ["value"],
              fieldSettings: {
                min: 1,
                max: 100000
              },
            },
            catalogReference: {
              label: "Catalog reference",
              type: "!struct",
              subfields: {
                catalogItemId: {
                  label: "Catalog item ID",
                  type: "text",
                  valueSources: ["value"],
                },
                appId: {
                  label: "ID of the catalog app",
                  type: "text",
                  valueSources: ["value"],
                },
              }
            },
            productName: {
              label: "Item name",
              type: "!struct",
              subfields: {
                original: {
                  label: "Original name",
                  type: "text",
                  valueSources: ["value"],
                },
                translated: {
                  label: "Translated name",
                  type: "text",
                  valueSources: ["value"],
                }
              },
              price: {
                label: "Price for a single item",
                type: "!struct",
                subfields: {
                  amount: {
                    label: "Amount",
                    type: "number", // converted
                    valueSources: ["value"],
                    preferWidgets: ['number'],
                  }
                },
              },

              physicalProperties: {
                label: "Physical properties of the item",
                type: "!struct",
                subfields: {
                  weight: {
                    label: "Line item weight",
                    type: "number",
                    valueSources: ["value"],
                  },
                  sku: {
                    label: "Stock-keeping unit",
                    type: "text",
                    valueSources: ["value"],
                  },
                  shippable: {
                    label: "Shippable?",
                    type: "boolean",
                    valueSources: ["value"],
                  },
                }
              },
              itemType: {
                label: "Item type",
                type: "!struct",
                subfields: {
                  preset: {
                    label: "Preset",
                    type: "text",
                    listValues: ['UNRECOGNISED', 'PHYSICAL', 'DIGITAL', 'GIFT_CARD', 'SERVICE'].map(v => ({ value: v, title: v })),
                    valueSources: ["value"],
                  },
                  custom: {
                    label: "Custom",
                    type: "text",
                    valueSources: ["value"],
                  }
                }
              },
              subscriptionOptionInfo: {
                label: "Subscription option information",
                type: "!struct",
                subfields: {
                  subscriptionSettings: {
                    label: "Subscription settings",
                    type: "!struct",
                    subfields: {
                      frequency: {
                        label: "Frequency",
                        type: "text",
                        listValues: ['UNDEFINED', 'DAY', 'WEEK', 'MONTH', 'YEAR'].map(v => ({ value: v, title: v })),
                        valueSources: ["value"],
                      },
                      autoRenewal: {
                        label: "Auto renewal",
                        type: "boolean",
                        valueSources: ["value"],
                      },
                      billingCycles: {
                        label: "Billing cycles",
                        type: "number",
                        valueSources: ["value"],
                      }
                    },
                  },
                  title: {
                    label: "Title",
                    type: "!struct",
                    subfields: {
                      original: {
                        label: "Original title",
                        type: "text",
                        valueSources: ["value"],
                      },
                      translated: {
                        label: "Translated title",
                        type: "text",
                        valueSources: ["value"],
                      }
                    }
                  },
                  description: {
                    label: "Description",
                    type: "!struct",
                    subfields: {
                      original: {
                        label: "Original description",
                        type: "text",
                        valueSources: ["value"],
                      },
                      translated: {
                        label: "Translated description",
                        type: "text",
                        valueSources: ["value"],
                      }
                    }
                  }
                }
              }
            }
          }
        },
        giftCard: {
          label: "Gift card",
          type: "!struct",
          subfields: {
            id: {
              label: "Gift card ID",
              type: "text",
              valueSources: ["value"],
            },
            obfuscatedCode: {
              label: "Obfuscated gift card code",
              type: "text",
              valueSources: ["value"],
            },
            amount: {
              label: "Gift card amount",
              type: "!struct",
              subfields: {
                amount: {
                  label: "Amount",
                  type: "number", // converted
                  valueSources: ["value"],
                }
              }
            },
            appId: {
              label: "ID of the app that issued the gift card",
              type: "text",
              valueSources: ["value"],
            }
          }
        },
        weightUnit: {
          label: "Weight unit",
          type: "text",
          listValues: ['UNSPECIFIED_WEIGHT_UNIT', 'KG', 'LB'].map(v => ({ value: v, title: v })),
          valueSources: ["value"],
        },
        priceSummary: {
          label: "Price summary",
          type: "!struct",
          subfields: {
            subtotal: {
              label: "Subtotal",
              type: "!struct",
              subfields: {
                amount: {
                  label: "Amount",
                  type: "number", // converted
                  valueSources: ["value"],
                }
              },
            },
            shipping: {
              label: "Shipping",
              type: "!struct",
              subfields: {
                amount: {
                  label: "Amount",
                  type: "number", // converted
                  valueSources: ["value"],
                }
              }
            },
            tax: {
              label: "Tax",
              type: "!struct",
              subfields: {
                amount: {
                  label: "Amount",
                  type: "number", // converted
                  valueSources: ["value"],
                }
              }
            },
            discount: {
              label: "Discount",
              type: "!struct",
              subfields: {
                amount: {
                  label: "Amount",
                  type: "number", // converted
                  valueSources: ["value"],
                }
              }

            },
            total: {
              label: "Total",
              type: "!struct",
              subfields: {
                amount: {
                  label: "Amount",
                  type: "number", // converted
                  valueSources: ["value"],
                }
              }
            },
            additionalFees: {
              label: "Additional fees",
              type: "!struct",
              subfields: {
                amount: {
                  label: "Amount",
                  type: "number", // converted
                  valueSources: ["value"],
                }
              }
            }
          }
        },
        billingInfo: {
          label: "Billing information",
          type: "!struct",
          subfields: {
            address: {
              label: "Billing address",
              type: "!struct",
              subfields: {
                country: {
                  label: "Country code",
                  type: "text",
                  valueSources: ["value"],
                },
                subdivision: {
                  label: "Code for a subdivision (such as state, prefecture, or province) in ISO 3166-2 format",
                  type: "text",
                  valueSources: ["value"],
                },
                city: {
                  label: "City name",
                  type: "text",
                  valueSources: ["value"],
                },
                postalCode: {
                  label: "Zip/postal code",
                  type: "text",
                  valueSources: ["value"],
                },
                streetAddress: {
                  label: "Street name and number",
                  type: "!struct",
                  subfields: {
                    number: {
                      label: "Street number",
                      type: "text",
                      valueSources: ["value"],
                    },
                    name: {
                      label: "Street name",
                      type: "text",
                      valueSources: ["value"],
                    },
                  }
                },
                addressLine: {
                  label: "Main address line, usually street and number as free text",
                  type: "text",
                  valueSources: ["value"],
                },
                addressLine2: {
                  label: "Free text providing more detailed address info",
                  type: "text",
                  valueSources: ["value"],
                },
              }
            },
            contactDetails: {
              label: "Contact details",
              type: "!struct",
              subfields: {
                firstName: {
                  label: "Contact's first name",
                  type: "text",
                  valueSources: ["value"],
                },
                lastName: {
                  label: "Contact's last name",
                  type: "text",
                  valueSources: ["value"],
                },
                phone: {
                  label: "Contact's phone number",
                  type: "text",
                  valueSources: ["value"],
                },
                company: {
                  label: "Contact's company name",
                  type: "text",
                  valueSources: ["value"],
                },
                email: {
                  label: "Email associated with the address",
                  type: "text",
                  valueSources: ["value"],
                },
                vatId: {
                  label: "Tax info (Brazil)",
                  type: "!struct",
                  subfields: {
                    id: {
                      label: "Customer's tax ID",
                      type: "text",
                      valueSources: ["value"],
                    },
                    type: {
                      label: "Tax type",
                      type: "text",
                      listValues: ['UNSPECIFIED', 'CPF', 'CNPJ'].map(v => ({ value: v, title: v })),
                      valueSources: ["value"],
                    },
                  }
                }
              }
            }
          }
        },
        shippingAddress: {
          label: "Billing information",
          type: "!struct",
          subfields: {
            address: {
              label: "Billing address",
              type: "!struct",
              subfields: {
                country: {
                  label: "Country code",
                  type: "text",
                  valueSources: ["value"],
                },
                subdivision: {
                  label: "Code for a subdivision (such as state, prefecture, or province) in ISO 3166-2 format",
                  type: "text",
                  valueSources: ["value"],
                },
                city: {
                  label: "City name",
                  type: "text",
                  valueSources: ["value"],
                },
                postalCode: {
                  label: "Zip/postal code",
                  type: "text",
                  valueSources: ["value"],
                },
                streetAddress: {
                  label: "Street name and number",
                  type: "!struct",
                  subfields: {
                    number: {
                      label: "Street number",
                      type: "text",
                      valueSources: ["value"],
                    },
                    name: {
                      label: "Street name",
                      type: "text",
                      valueSources: ["value"],
                    },
                  }
                },
                addressLine: {
                  label: "Main address line, usually street and number as free text",
                  type: "text",
                  valueSources: ["value"],
                },
                addressLine2: {
                  label: "Free text providing more detailed address info",
                  type: "text",
                  valueSources: ["value"],
                },
              }
            },
            contactDetails: {
              label: "Contact details",
              type: "!struct",
              subfields: {
                firstName: {
                  label: "Contact's first name",
                  type: "text",
                  valueSources: ["value"],
                },
                lastName: {
                  label: "Contact's last name",
                  type: "text",
                  valueSources: ["value"],
                },
                phone: {
                  label: "Contact's phone number",
                  type: "text",
                  valueSources: ["value"],
                },
                company: {
                  label: "Contact's company name",
                  type: "text",
                  valueSources: ["value"],
                },
                email: {
                  label: "Email associated with the address",
                  type: "text",
                  valueSources: ["value"],
                },
                vatId: {
                  label: "Tax info (Brazil)",
                  type: "!struct",
                  subfields: {
                    id: {
                      label: "Customer's tax ID",
                      type: "text",
                      valueSources: ["value"],
                    },
                    type: {
                      label: "Tax type",
                      type: "text",
                      listValues: ['UNSPECIFIED', 'CPF', 'CNPJ'].map(v => ({ value: v, title: v })),
                      valueSources: ["value"],
                    },
                  }
                }
              }
            }
          }
        },
        shippingInfo: {
          label: "Shipping information",
          type: "!struct",
          subfields: {
            selectedCarrierServiceOption: {
              label: "Selected carrier shipping option",
              type: "!struct",
              subfields: {
                code: {
                  label: "Unique identifier of selected option",
                  type: "text",
                  valueSources: ["value"],
                },
              }
            }
          }
        },
        appliedDiscounts: {
          label: "Applied discounts",
          type: "!group",
          mode: "array",
          // @ts-ignore
          fieldSettings: {
          },
          subfields: {
            discountType: {
              label: "Discount type",
              type: "text",
              listValues: ['GLOBAL', 'SPECIFIC_ITEMS', 'SHIPPING'].map(v => ({ value: v, title: v })),
              valueSources: ["value"],
            },
            lineItemIds: {
              label: "Line item IDs",
              type: "text",
              mode: "array",
              // @ts-ignore
              fieldSettings: {
              },
              valueSources: ["value"],
            },
            coupon: {
              label: "Coupon",
              type: "!struct",
              subfields: {
                // id / code / name / amount
                id: {
                  label: "Coupon ID",
                  type: "text",
                  valueSources: ["value"],
                },
                code: {
                  label: "Coupon code",
                  type: "text",
                  valueSources: ["value"],
                },
                name: {
                  label: "Coupon name",
                  type: "text",
                  valueSources: ["value"],
                },
                amount: {
                  label: "Coupon amount",
                  type: "number", // converted
                  valueSources: ["value"],
                }
              }
            },
            merchantDiscount: {
              label: "Merchant discount",
              type: "!struct",
              subfields: {
                // amount
                amount: {
                  label: "Amount",
                  type: "number", // converted
                  valueSources: ["value"],
                }
              }
            },
            discountRule: {
              label: "Discount rule",
              type: "!struct",
              subfields: {
                // id / name: {original, translated} / amount
                id: {
                  label: "ID",
                  type: "text",
                  valueSources: ["value"],
                },
                name: {
                  label: "Name",
                  type: "!struct",
                  subfields: {
                    original: {
                      label: "Original name",
                      type: "text",
                      valueSources: ["value"],
                    },
                    translated: {
                      label: "Translated name",
                      type: "text",
                      valueSources: ["value"],
                    }
                  }
                },
                amount: {
                  label: "Amount",
                  type: "number", // converted
                  valueSources: ["value"],
                }
              }
            }
          }
        }
      }
    }
  }
}

const Preset = {
  'additional-fees': [
    {
      id: 0,
      prefix: <Icons.Payment />,
      title: 'Low Order Fee',
      subtitle: 'Charge $5 for order amount less than $50',
      suffix: 'Best for covering costs',
      input: {
        "errors": [],
        "logic": {
          "and": [
            {
              "<": [
                {
                  "reduce": [
                    {
                      "filter": [
                        {
                          "var": "lineItems"
                        },
                        {
                          "<=": [
                            {
                              "var": "quantity"
                            },
                            1
                          ]
                        }
                      ]
                    },
                    {
                      "+": [
                        1,
                        {
                          "var": "accumulator"
                        }
                      ]
                    },
                    0
                  ]
                },
                1
              ]
            }
          ]
        },
        "data": {
          "lineItems": [
            {
              "quantity": null
            }
          ]
        }
      },
      output: [
        {
          "code": "LOW_VALUE_FEE",
          "name": "Small Order Fee",
          "price": "5.00",
          "taxDetails": {
            "taxable": true
          }
        }
      ]
    },
    {
      id: 1,
      prefix: <Icons.GetStarted />,
      title: 'Setup Fee',
      subtitle: 'Charge $99 for order that includes product item with name "with Setup"',
      suffix: 'Best for products with setup',
      input: {
        "errors": [],
        "logic": {
          "and": [
            {
              "some": [
                {
                  "var": "lineItems"
                },
                {
                  "ends_with": [
                    {
                      "var": "productName"
                    },
                    "with Setup"
                  ]
                }
              ]
            }
          ]
        },
        "data": {
          "lineItems": [
            {
              "productName": null
            }
          ]
        }
      },
      output: [
        {
          "code": "SETUP_FEE",
          "name": "Setup fee for workers",
          "price": "99.00",
          "taxDetails": {
            "taxable": true
          }
        }
      ]
    },
    {
      id: 2,
      prefix: <Icons.Gift />,
      title: 'Gift Wrapping',
      subtitle: 'Take $1 for gift wrapping when address includes "Gift"',
      suffix: 'Best for gift wrapping',
      input: {
        "errors": [],
        "logic": {
          "and": [
            {
              "in": [
                "GIF",
                {
                  "var": "shippingAddress.addressLine2"
                }
              ]
            }
          ]
        },
        "data": {
          "shippingAddress": {
            "addressLine2": null
          }
        }
      },
      output: [
        {
          "code": "GIFT_WRAPPING",
          "name": "Wrapping fee for gift with hidden address",
          "price": "1.00",
          "taxDetails": {
            "taxable": true
          }
        }
      ]
    },
    {
      id: 3,
      prefix: <Icons.Premium />,
      title: 'Fees for VIPs',
      subtitle: 'Charge $20 per order when customer is VIP',
      suffix: 'Best for VIP customers',
      input: {
        "errors": [],
        "logic": {
          "and": [
            {
              "some": [
                {
                  "var": "buyerDetails"
                },
                {
                  "some": [
                    {
                      "var": "contactDetails"
                    },
                    {
                      "ends_with": [
                        {
                          "var": "email"
                        },
                        "@vip-company.com"
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        },
        "data": {
          "buyerDetails": [
            {
              "contactDetails": [
                {
                  "email": null
                }
              ]
            }
          ]
        }
      },
      output: [
        {
          "code": "VIP_FEE",
          "name": "Special services fee as discussed",
          "price": "20",
          "taxDetails": {
            "taxable": true
          }
        }
      ]
    }],
  "shipping-rates": [
    {
      id: 1,
      prefix: <Icons.Gift />,
      title: 'Special Shipping Rate for Name with "Tom"',
      subtitle: 'Display $0 shipping option for customer with name "Tom"',
      suffix: 'Best for online promotions',
      input: {
        "errors": [],
        "logic": {
          "or": [
            {
              "in": [
                "Tom",
                {
                  "var": "buyerContactDetails.firstName"
                }
              ]
            },
            {
              "in": [
                "To",
                {
                  "var": "buyerContactDetails.lastName"
                }
              ]
            }
          ]
        },
        "data": {
          "buyerContactDetails": {
            "firstName": null,
            "lastName": null
          }
        }
      },
      output: [
        {
          "code": "TOM_FREE_SHIPPING",
          "title": "Free Shipping for Tom",
          "logistics": {
            "deliveryTime": "Special for you! We will get this delivered in 3 days.",
            "instructions": "/"
          },
          "cost": {
            "price": "0",
            "currency": "USD"
          }
        }
      ]
    },
    {
      id: 1,
      prefix: <Icons.Freeze />,
      title: 'Special Cold Delivery Rate',
      subtitle: 'Display $9.99 shipping option for order that includes product item with name "Fresh"',
      suffix: 'Best for fresh products',
      input: {
        "errors": [],
        "logic": {
          "and": [
            {
              "some": [
                {
                  "var": "lineItems"
                },
                {
                  "starts_with": [
                    {
                      "var": "name"
                    },
                    "Cold"
                  ]
                }
              ]
            },
            {
              "some": [
                {
                  "var": "lineItems"
                },
                {
                  "!": {
                    "==": [
                      {
                        "var": "name"
                      },
                      "Hot"
                    ]
                  }
                }
              ]
            }
          ]
        },
        "data": {
          "lineItems": [
            {
              "name": null
            }
          ]
        }
      },
      output: [
        {
          "code": "FREEZE_SHIPPING",
          "title": "Cold Shipping Like 0 Degree",
          "logistics": {
            "deliveryTime": "For cold items, we will deliver today.",
            "instructions": "/"
          },
          "cost": {
            "price": "9.99",
            "currency": "USD"
          }
        }
      ]
    }
  ],
  'validations': [{
    id: 1,
    prefix: <Icons.Payment />,
    title: 'Minimum Spend for Gold Items',
    subtitle: 'Display error when order amount is less than $1000 and includes product item with name "Gold"',
    suffix: 'Best for covering costs',
    input: {
      "errors": [],
      "logic": {
        "!": {
          "and": [
            {
              ">": [
                {
                  "var": "validationInfo.priceSummary.subtotal.amount"
                },
                1000
              ]
            }
          ]
        }
      },
      "data": {
        "validationInfo": {
          "priceSummary": {
            "subtotal": {
              "amount": null
            }
          }
        }
      }
    },
    output: [
      {
        "target": {
          "other": {
            "name": "OTHER_DEFAULT"
          }
        },
        "description": "Please purchase over $1000 for delivery.",
        "severity": "ERROR"
      }
    ]
  },
  {
    id: 1,
    prefix: <Icons.Cart />,
    title: 'Minimum Quantity for Silver Items',
    subtitle: 'Display error when order line items count is less than 5 and includes product item with name "Silver"',
    suffix: 'Best for covering costs',
    input: {
      "errors": [],
      "logic": {
        "!": {
          "and": [
            {
              ">": [
                {
                  "reduce": [
                    {
                      "var": "validationInfo.lineItems"
                    },
                    {
                      "+": [
                        1,
                        {
                          "var": "accumulator"
                        }
                      ]
                    },
                    0
                  ]
                },
                5
              ]
            }
          ]
        }
      },
      "data": {
        "validationInfo": {
          "lineItems": null
        }
      }
    },
    output: [
      {
        "target": {
          "other": {
            "name": "OTHER_DEFAULT"
          }
        },
        "description": "Please purchase more than 5 different products for delivery. Otherwise, the delivery will takes 1 year.",
        "severity": "WARNING"
      }
    ]
  }]
}

const CONTENT = [
  'and take live payments with pay button',
  '0% fee added on top of LINE Pay',
];

const INTERVAL = 4000;

function App() {
  const [currentRule, setCurrentRule] = useState<any>({});
  const config: Config = {
    ...InitialConfig,
    fields: (Fields as any)[currentRule.type || "additional-fees"]
  };
  config.operators.starts_with.jsonLogic = "starts_with";
  config.operators.ends_with.jsonLogic = "ends_with";

  const renderBuilder = useCallback((props: BuilderProps) => (
    <Builder {...props} />
  ), []);

  ReactTagManager.init(tagManagerArgs)

  /* Wix Owner App - Start */
  const isOwnerApp = new URLSearchParams(window.location.search).get('openedIn') === 'ownerApp';
  const isPremiumUpgradeDisallowed = new URLSearchParams(window.location.search).get('isPremiumUpgradeAllowed') === 'false';
  /* Wix Owner App - End */

  const [isUpgraded, setIsUpgraded] = React.useState(false);
  const [instanceData, setInstanceData] = React.useState({
    instance: {
      isFree: true,
      availablePlans: [],
      instanceId: ""
    }
  });

  const [isGroupUsed, setIsGroupUsed] = React.useState(false);
  const [setupState, setSetupState] = React.useState(0);
  const [allRules, setAllRules] = useState<any[]>([]);
  const [isSaving, setIsSaving] = useState(false);
  const [notification, setNotification] = useState<any>({});
  const [isUpgradeModalOpen, setIsUpgradeModalOpen] = useState(false);
  const [isErrored, setIsErrored] = useState(false);

  const [state, setState] = useState({
    tree: QbUtils.checkTree(QbUtils.loadTree(queryValue), config),
    config: config
  });

  const isFree = instanceData?.instance?.isFree !== false;

  const [index, setIndex] = React.useState<number>(0);
  const [isUpgradeBannerOpen, setIsUpgradeBannerOpen] = React.useState<boolean>(isFree);

  React.useEffect(() => {
      const sliderInterval = setInterval(() => {
          setIndex((prevIndex) => (prevIndex + 1) % CONTENT.length);
      }, INTERVAL);

      return () => clearInterval(sliderInterval);
  }, [CONTENT, INTERVAL]);

  useEffect(() => {
    setIsGroupUsed(((currentRule.input as any)?.children1 || []).filter((v: any) => v.type === 'group').length > 0);
    console.log(currentRule);
  }, [currentRule]);

  useEffect(() => {
    setIntercomIdentity();
    setInstance();
    getRules();
  }, [])

  useEffect(() => {
    setCurrentRule({
      ...currentRule,
      input: QbUtils.jsonLogicFormat(state.tree, state.config)
    })
  }, [state])

  const token = new URLSearchParams(window.location.search).get('token');
  const instance = new URLSearchParams(window.location.search).get('instance')

  if (token) {
    window.location.href = `https://www.wix.com/installer/install?token=${token}&appId=e79759eb-0d13-4d31-8171-f97c3d61c638&redirectUrl=https://certifiedcode.editorx.io/logic-for-ecom/_functions/@certifiedcode/base-backend/auth`;
  }

  if (!!token) {
    return <WixDesignSystemProvider features={{ newColorsBranding: true }}>
      <Page height='100vh'>
        <Page.Content>
          <Box height={"90vh"} direction="vertical">
            <Loader text="Loading" />
          </Box>
        </Page.Content>
      </Page></WixDesignSystemProvider>
  }

  if (isErrored) {
    return <WixDesignSystemProvider features={{ newColorsBranding: true }}>
      <Page height='100vh'>
        <Page.Content>
          <Box height={"90vh"} direction="vertical">
            <Loader status="error" text="Something went wrong" />
          </Box>
        </Page.Content>
      </Page></WixDesignSystemProvider>
  }

  const BASE_URL = `https://certifiedcode.editorx.io/logic-for-ecom/_functions`

  function setIntercomIdentity() {
    fetch(BASE_URL + "/@certifiedcode/base-backend/intercom", {
      method: "GET",
      headers: {
        "Authorization": instance || ""
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data.hash && data.email) {
          window.Intercom("boot", {
            email: data.email,
            user_hash: data.hash
          });
        }
      })
      .catch(() => {
        setIsErrored(true);
      })
  }

  function setInstance() {
    setIsSaving(true);
    fetch(BASE_URL + "/@certifiedcode/base-backend/instance", {
      method: "GET",
      headers: {
        "Authorization": instance || ""
      }
    })
      .then(response => response.json())
      .then(data => {
        setIsSaving(false);
        setInstanceData(data);
        setIsUpgraded(data.instance.isFree === false);
        setIsUpgradeBannerOpen(data.instance.isFree !== false)
      })
      .catch(() => {
        setIsErrored(true);
        setIsSaving(false);
        setNotification({
          message: "We couldn't load the app, please try again later",
          theme: "error",
          show: true
        })
      })
  }

  function getRules() {
    setIsSaving(true);
    fetch(BASE_URL + "/rules", {
      method: "GET",
      headers: {
        "Authorization": instance || ""
      }
    })
      .then(response => response.json())
      .then(data => {
        setIsSaving(false);
        setAllRules(data);
      })
      .catch(() => {
        setIsSaving(false);
        setNotification({
          message: "We couldn't load your rules, please try again later",
          theme: "error",
          show: true
        })
      })
  }

  function createRule() {
    const sameTypeRule = !!allRules.find((rule: any) => rule.type === currentRule.type);
    if (!currentRule._id && !isUpgraded && sameTypeRule) {
      setIsUpgradeModalOpen(true);
      return;
    }
    setIsSaving(true);
    if (currentRule._id) {
      fetch(BASE_URL + "/rules" + "/" + currentRule._id, {
        method: "PUT",
        headers: {
          "Authorization": instance || ""
        },
        body: JSON.stringify(currentRule)
      })
        .then(response => response.json())
        .then(data => {
          if (currentRule.type === "shipping-rates") {
            window.Intercom("showArticle", 8078667)
          }
          setCurrentRule({});
          setSetupState(0);
          getRules();
          setNotification({
            message: "Rule updated successfully",
            theme: "success",
            show: true
          })
        })
        .catch(() => {
          setIsSaving(false);
          setNotification({
            message: "We couldn't update your rule, please try again later",
            theme: "error",
            show: true
          })
        })
    } else {
      fetch(BASE_URL + "/rules", {
        method: "POST",
        headers: {
          "Authorization": instance || ""
        },
        body: JSON.stringify(currentRule)
      })
        .then(response => response.json())
        .then(data => {
          setCurrentRule({});
          setSetupState(0);
          getRules();
          setNotification({
            message: "Rule created successfully",
            theme: "success",
            show: true
          })
        })
        .catch(() => {
          getRules();
          setNotification({
            message: "We couldn't create your rule, please try again later",
            theme: "error",
            show: true
          })
        })
    }
  }

  function deleteRule(id: string) {
    setIsSaving(true);
    fetch(BASE_URL + "/rules/" + id, {
      method: "DELETE",
      headers: {
        "Authorization": instance || ""
      }
    })
      // .then(response => response.json())
      .then(data => {
        getRules();
        setNotification({
          message: "Rule deleted successfully",
          theme: "success",
          show: true
        })
      })
      .catch(() => {
        getRules();
        setNotification({
          message: "We couldn't delete your rule, please try again later",
          theme: "error",
          show: true
        })
      })
  }

  const presetDropdown = () => {
    const listItemSelectOptions = (Preset as any)[currentRule.type || "additional-fees"].map((v: any) => { return listItemSelectBuilder(v) })
    return (
      <Dropdown
        placeholder="Choose a preset to get started"
        // @ts-ignore
        options={listItemSelectOptions}
        onSelect={(e: any) => {
          const presetItem = (Preset as any)[currentRule.type || "additional-fees"][e.id]
          setCurrentRule({ ...currentRule, input: presetItem.input, output: presetItem.output })
          setState({
            // @ts-ignore
            tree: QbUtils.checkTree(QbUtils.loadFromJsonLogic(presetItem?.input?.logic, config), config),
            config: config
          })
        }}
      />
    );
  };

  const RuleList = () => {
    const columns = [{
      title: 'Name',
      render: (row: any) => row.name,
    },
    {
      title: 'Type',
      render: (row: any) => (row.type === 'shipping-rates' ? <Tooltip content="Custom Shipping Options"><Box direction='horizontal' gap='SP1'><Icons.Shipping /><TextButton onClick={() => { window.Intercom("showArticle", 8078667) }}>Does not work?</TextButton></Box></Tooltip> : <Tooltip content="Additional Fees"><Icons.Payment /></Tooltip>),
    },
    {
      title: 'Status',
      render: (row: any) => <BadgeSelect
        type="solid"
        options={[
          { id: '0', skin: 'success', text: 'Active' },
          { id: '1', skin: 'neutralLight', text: 'Disabled' },
        ]}
        selectedId={!!row.isEnabled ? '0' : '1'}
        onSelect={(e) => {
          setIsSaving(true);
          fetch(BASE_URL + "/rules/" + row._id, {
            method: "PUT",
            headers: {
              "Authorization": instance || ""
            },
            body: JSON.stringify({
              ...row,
              isEnabled: e.id === '0'
            })
          })
            .then(response => response.json())
            .then(data => {
              getRules();
              setNotification({
                message: "Rule updated successfully",
                theme: "success",
                show: true
              })
            })
            .catch(() => {
              getRules();
              setNotification({
                message: "We couldn't update your rule, please try again later",
                theme: "error",
                show: true
              })
            })
        }}
      />
    },
    {
      title: '',
      render: (row: any) => (
        <TableActionCell
          // onClick={() => { }}
          primaryAction={{
            text: 'Edit',
            onClick: () => {
              setCurrentRule(row);
              if (row?.input?.logic) {
                setState({
                  // @ts-ignore
                  tree: QbUtils.checkTree(QbUtils.loadFromJsonLogic(row.input.logic, config), config),
                  config: config
                })
              }
              setSetupState(1);
            },
          }}
          secondaryActions={[
            {
              text: 'Duplicate to edit',
              icon: <Icons.DuplicateSmall />,
              onClick: () => {
                // setCurrentRule(row) but only keep name, isEnabled, type, input, output
                setCurrentRule({
                  name: (row.name || "") + ' copy',
                  isEnabled: row.isEnabled || true,
                  type: row.type || 'additional-fees',
                  input: row.input,
                  output: row.output
                });
                setSetupState(1);
              },
            },
            {
              text: 'Delete',
              icon: <Icons.DeleteSmall />,
              onClick: () => {
                deleteRule(row._id);
              },
            },
          ]}
        />
      ),
    },
    ]


    if (isSaving) {
      return <Loader size="large" />
    }

    return (
      <Card>
        {allRules.length === 0 ?
          <EmptyState
            theme="page"
            image={
              <Image
                width="120px"
                height="120px"
                src="https://www.wix-style-react.com/storybook/no_categories.png"
                transparent
              />
            }
            title="No rules enabled"
            subtitle="Manage business rules to your stores with Logic for Ecom"
          >
            {<TextButton prefixIcon={<Icons.Add />} onClick={() => {
              setCurrentRule(Templates.additionalFees); setSetupState(1)
            }}>Setup Rule</TextButton>}
          </EmptyState> :
          <Table data={allRules} columns={columns} onRowClick={() => { }} >
            <TableToolbar>
              <TableToolbar.Title>Rules</TableToolbar.Title>
              <TableToolbar.ItemGroup>
                <TableToolbar.Item>
                  <Button prefixIcon={<Icons.Add />}
                    onClick={() => { setSetupState(1) }}
                  >Add Rule</Button>
                </TableToolbar.Item>
              </TableToolbar.ItemGroup>
            </TableToolbar>
            <Table.Content />
          </Table>}
      </Card>
    );
  }

  const RuleCreationStep1 = () => {
    var mapping = {
      "additional-fees": "additionalFees",
      "shipping-rates": "shippingRates",
      "validations": "validations"
    }
    const renderThumbnail = ({ title, subtitle, id, icon }: any) => (
      <Thumbnail selected={currentRule.type === id} onClick={() => {
        // @ts-expect-error
        setCurrentRule(Templates[mapping[id]])
      }}>
        <Box padding="18px">
          <Box gap="12px">
            {icon}
            <Box direction="vertical">
              <Text size="medium" weight="bold">
                {title}
              </Text>
              <Box>
                <Text size="small" secondary>
                  {subtitle}
                </Text>
              </Box>
            </Box>
          </Box>
        </Box>
      </Thumbnail>
    );


    if (isSaving) {
      return <Loader size="large" />
    }

    return <Card>
      <Card.Header title="Create a rule" />
      <Card.Divider />
      <Card.Content>
        <Layout cols={12}>
          <Cell span={8}>
            <Layout cols={1}>
              <FormField label="Name">
                <Input placeholder="Enter rule name"
                  onChange={(e) => {
                    setCurrentRule({
                      ...currentRule,
                      name: e.target.value
                    })
                  }
                  }
                  value={currentRule.name}
                />
              </FormField>
              <FormField
                label="Select a type"
                infoContent="Choose the type of rule you want to create"
              >
                <Layout cols={1} gap="12px">
                  {renderThumbnail({
                    title: 'Additional Fees',
                    subtitle: 'Add fees to the order based on conditions',
                    id: "additional-fees",
                    icon: <Icons.Payment />,
                  })}
                  {renderThumbnail({
                    title: 'Dynamic Shipping Options',
                    subtitle: 'Add shipping options to the order based on conditions',
                    id: "shipping-rates",
                    icon: <Icons.Shipping />,
                  })}
                  {
                    // (currentRule.name === 'My validations rule' || currentRule.type === 'validations')
                    // ? 
                    renderThumbnail({
                      title: <Box direction="horizontal" gap="SP2"><Text weight='bold'>Validations</Text><Tooltip content="Newly released feature might have some bugs. Feel free report to us if you found one!"><Badge size='tiny' skin="danger">ALPHA</Badge></Tooltip></Box>,
                      subtitle: 'Validates the cart and/or checkout based on conditions',
                      id: "validations",
                      icon: <Icons.Payment />,
                    })
                    // : <></>
                  }
                </Layout>
              </FormField>
            </Layout>
          </Cell>
        </Layout>
      </Card.Content>
    </Card>
  };

  const RuleCreationStep2 = () => {

    if (isSaving) {
      return <Loader size="large" />
    }

    return <Card>
      <Card.Header title="When the following condition(s) matches:"
        subtitle={
          currentRule.type === 'shipping-rates' ? "Display the following shipping option(s) to checkout:" : (currentRule.type === 'additional-fees' ? 'Apply the following fee(s) to checkout:' : 'Validate the following rule(s) to checkout:')
        }
        suffix={
          <Button size="small" prefixIcon={<Icons.Confirm />}
            disabled={currentRule?.output?.length === 0 || isSaving}
            onClick={() => {
              createRule();
            }}
          >
            Set Rule Live
          </Button>
        } />
      <FloatingNotification
        type="preview"
        fullWidth
        showCloseButton={false}
        prefixIcon={<Icons.PromoteFilledSmall />}
        text={`Want to ${currentRule.type === 'shipping-rates' ? "display the option(s)" : (currentRule.type === 'additional-fees' ? 'apply the fee(s)' : 'validation(s)')} to all orders? Leave the condition(s) empty!`}
      />
      {(isGroupUsed && !isUpgraded) ? <FloatingNotification
        type="premium"
        fullWidth
        showCloseButton={false}
        prefixIcon={<Icons.PremiumFilledSmall />}
        text="To use Group, upgrade to Premium. Remove the Group(s) to continue using the free version."
      /> : <></>}
      <Card.Content>
        <Layout>
          <Cell>
            <FormField label="Choose a preset to apply (optional):">
              {presetDropdown()}
            </FormField>
          </Cell>
          <Cell>
            <Query
              {...config}
              value={state.tree}
              onChange={(immutableTree: ImmutableTree, config: Config) => {
                // Tip: for better performance you can apply `throttle` - see `examples/demo`
                setState(prevState => ({ ...prevState, tree: immutableTree, config: config }));
                // `jsonTree` can be saved to backend, and later loaded to `queryValue`

                // setCurrentRule({
                //   ...currentRule,
                //   input: jsonTree
                // });
              }}
              renderBuilder={renderBuilder}
            />
            <Layout>
              {(currentRule?.input?.errors || []).map((v: string) => { return <Cell span={12}><FloatingNotification text={v} showCloseButton={false} /></Cell> })}
            </Layout>
          </Cell>
        </Layout>
      </Card.Content>
      <Card.Header title={`Apply the following ${currentRule.type === 'shipping-rates' ? 'option(s)' : ((currentRule.type === 'additional-fees' ? 'fee(s)' : 'rule(s)'))} to checkout:`} />
      <Card.Divider />
      <Card.Content>
        <Box direction="vertical" gap="SP2">
          <Layout>
            {(currentRule.output || []).map((fee: any, index: any) => (
              <Cell span={6} key={index}>
                <Card showShadow controls={
                  <Box>
                    <CloseButton size="medium">
                      <Icons.Help />
                    </CloseButton>
                    <CloseButton size="medium" onClick={() => {
                      const newFees = [...currentRule.output];
                      newFees.splice(index, 1);
                      setCurrentRule({
                        ...currentRule,
                        output: newFees
                      })
                    }} />
                  </Box>
                }>
                  <Card.Content>
                    {currentRule.type === 'shipping-rates' ? <Box direction="vertical" gap="SP2" width="300px">
                      <FormField
                        label="Code"
                        infoContent={`Unique code that acts as an ID for a shipping rate. For example, "usps_std_overnight".`}
                      >
                        <Input
                          value={fee.code}
                          status={(fee.code.length > 50 || fee.code.length < 1 || currentRule.output.filter((f: { code: any; }, i: any) => i !== index && f.code === fee.code).length > 0) ? "error" : undefined}
                          statusMessage={(fee.code.length > 50 || fee.code.length < 1) ? "Code must be between 1 and 50 characters" : (currentRule.output.filter((f: { code: any; }, i: any) => i !== index && f.code === fee.code).length > 0) ? "Code must be unique" : undefined}
                          onChange={(e) => {
                            const newFees = [...currentRule.output];
                            newFees[index].code = e.currentTarget.value;
                            setCurrentRule({
                              ...currentRule,
                              output: newFees
                            });
                          }}
                        ></Input>
                      </FormField>
                      <FormField
                        label="Title"
                        infoContent={`Shipping rate title. For example, "USPS Standard Overnight Delivery", "Standard", or "First-Class Package International".`}
                      >
                        <Input
                          value={fee.title}
                          status={!!fee.title ? undefined : "error"}
                          onChange={(e) => {
                            const newFees = [...currentRule.output];
                            newFees[index].title = e.currentTarget.value;
                            setCurrentRule({
                              ...currentRule,
                              output: newFees
                            });
                          }}
                        ></Input>
                      </FormField>
                      <Text>Shipping logistics</Text>
                      <FormField
                        label="Delivery Time"
                        infoContent={`When the item is expected to be delivered in free text. For example, "3-5 business days".`}
                      >
                        <Input
                          value={fee.logistics.deliveryTime}
                          status={!!fee.logistics.deliveryTime ? undefined : "error"}
                          onChange={(e) => {
                            const newFees = [...currentRule.output];
                            newFees[index].logistics.deliveryTime = e.currentTarget.value;
                            setCurrentRule({
                              ...currentRule,
                              output: newFees
                            });
                          }}
                        ></Input>
                      </FormField>
                      <FormField
                        label="Instructions"
                        infoContent={`Instructions for delivery. For example, for pickup: "Ensure to come during business hours, and please don't park in the disabled spot".`}
                      >
                        <Input
                          value={fee.logistics.instructions}
                          status={!!fee.logistics.instructions ? undefined : "error"}
                          onChange={(e) => {
                            const newFees = [...currentRule.output];
                            newFees[index].logistics.instructions = e.currentTarget.value;
                            setCurrentRule({
                              ...currentRule,
                              output: newFees
                            });
                          }}
                        ></Input>
                      </FormField>
                      <FormField
                        label="Enable Pickup"
                        infoContent="Fill in the pickup address to enable pickup for this shipping option."
                        suffix={
                          <Badge skin="warningLight" size="tiny">
                            Coming Soon
                          </Badge>}
                      >
                        <ToggleSwitch
                          disabled
                          checked={false}
                          onChange={() => {
                          }}
                        />
                      </FormField>
                      <Text>Shipping cost</Text>
                      <FormField
                        label="Price"
                        infoContent={`The shipping rate's price. Must align with the currency's decimal separator.`}
                      >
                        <Input
                          value={fee.cost.price}
                          status={
                            // check if convertable to number
                            isNaN(Number(fee.cost.price)) || Number(fee.cost.price) < 0 || Number(fee.cost.price) > 1000000000 ? "error" : undefined
                          }
                          onChange={(e) => {
                            const newFees = [...currentRule.output];
                            newFees[index].cost.price = e.currentTarget.value;
                            setCurrentRule({
                              ...currentRule,
                              output: newFees
                            });
                          }}
                        ></Input>
                      </FormField>
                      {/* <FormField
                        label="Currency"
                        infoContent={`Instructions for delivery. For example, for pickup: "Ensure to come during business hours, and please don't park in the disabled spot".`}
                      >
                        <Input
                          value={fee.cost.currency}
                          status={!!fee.cost.currency? undefined : "error"}
                          onChange={(e) => {
                            const newFees = [...currentRule.output];
                            newFees[index].cost.currency = e.currentTarget.value;
                            setCurrentRule({
                              ...currentRule,
                              output: newFees
                            });
                          }}
                        ></Input>
                      </FormField> */}
                      <FormField
                        label="Additional Charges"
                        infoContent={`Additional costs. For example, a handling fee for packaging fragile items.`}
                        suffix={
                          <Badge skin="warningLight" size="tiny">
                            Coming Soon
                          </Badge>}
                      >
                        <SkeletonGroup skin="light">
                          <Layout>
                            <Cell>
                              <SkeletonRectangle height="30px" />
                            </Cell>
                          </Layout>
                        </SkeletonGroup>
                      </FormField>
                    </Box> : <></>}
                    {currentRule.type === 'additional-fees' ? <Box direction="vertical" gap="SP2" width="300px">
                      <FormField
                        label="Code"
                        infoContent="Additional fee's unique code or ID"
                      >
                        <Input
                          value={fee.code}
                          status={(fee.code.length > 50 || fee.code.length < 1 || currentRule.output.filter((f: { code: any; }, i: any) => i !== index && f.code === fee.code).length > 0) ? "error" : undefined}
                          statusMessage={(fee.code.length > 50 || fee.code.length < 1) ? "Code must be between 1 and 50 characters" : (currentRule.output.filter((f: { code: any; }, i: any) => i !== index && f.code === fee.code).length > 0) ? "Code must be unique" : undefined}
                          onChange={(e) => {
                            const newFees = [...currentRule.output];
                            newFees[index].code = e.currentTarget.value;
                            setCurrentRule({
                              ...currentRule,
                              output: newFees
                            });
                          }}
                        ></Input>
                      </FormField>
                      <FormField
                        label="Name"
                        infoContent="Additional fee's name. Max: 50 characters"
                      >
                        <Input
                          value={fee.name}
                          status={(fee.name.length > 50 || fee.name.length < 1) ? "error" : undefined}
                          statusMessage={(fee.name.length > 50 || fee.name.length < 1) ? "Name must be between 1 and 50 characters" : undefined}
                          onChange={(e) => {
                            const newFees = [...currentRule.output];
                            newFees[index].name = e.currentTarget.value;
                            setCurrentRule({
                              ...currentRule,
                              output: newFees
                            })
                          }}
                        ></Input>
                      </FormField>
                      <FormField
                        label="Price"
                        infoContent="Total additional fees. This price does not include any taxes that may apply to these additional fees."
                      >
                        <Input
                          value={fee.price}
                          type="number"
                          status={
                            // check if convertable to number
                            (isNaN(Number(fee.price)) || Number(fee.price) < 0 || fee.price.length === 0) ? "error" : undefined
                          }
                          statusMessage={
                            // check if convertable to number
                            (isNaN(Number(fee.price)) || Number(fee.price) < 0 || fee.price.length === 0) ? "Price must be a positive number" : undefined
                          }
                          onChange={(e) => {
                            const newFees = [...currentRule.output];
                            newFees[index].price = e.currentTarget.value;
                            setCurrentRule({
                              ...currentRule,
                              output: newFees
                            })
                          }}
                        ></Input>
                      </FormField>
                      <FormField
                        label="Taxable"
                        infoContent="Whether additional fees are taxable."
                      >
                        <ToggleSwitch
                          checked={fee.taxDetails.taxable}
                          onChange={() => {
                            const newFees = [...currentRule.output];
                            newFees[index].taxDetails.taxable = !newFees[index].taxDetails
                              .taxable;
                            setCurrentRule({
                              ...currentRule,
                              output: newFees
                            })
                          }}
                        />
                      </FormField>
                    </Box> : <></>}
                    {currentRule.type === 'validations' ? <Box direction="vertical" gap="SP2" width="300px">
                      <FormField
                        label="Severity"
                        infoContent="Severity of the violation. The violations are shown on the cart and checkout pages. A warning is displayed as yellow, and allows a site visitor to proceed with caution. An error is displayed as red, and doesn't allow a site visitor to proceed with the eCommerce flow."
                      >
                        <BadgeSelect size="medium" uppercase={true}
                          options={[
                            { id: 'WARNING', skin: 'warning', text: 'Warning (can checkout)' },
                            { id: 'ERROR', skin: 'danger', text: 'Error (cannot checkout)' },
                          ]}
                          onSelect={(e) => {
                            const newViolation = [...currentRule.output];
                            newViolation[index].severity = e.id
                            setCurrentRule({
                              ...currentRule,
                              output: newViolation
                            })
                          }}
                          selectedId={fee.severity}
                        />
                      </FormField>
                      <FormField
                        label={<Text>Target <Badge size="tiny">Coming soon</Badge></Text>}
                        infoContent="Target location on a checkout or cart page where the violation will be displayed."
                      >
                        <SkeletonGroup skin="light">
                          <Layout>
                            <Cell>
                              <SkeletonRectangle height="30px" />
                            </Cell>
                          </Layout>
                        </SkeletonGroup>
                      </FormField>
                      <FormField
                        label="Description"
                        infoContent={`Violation description. Can include rich text. Only HTTP or HTTPS links in the following format are allowed: <a href="https://www.wix.com">Click me</a>.`}
                      >
                        <InputArea
                          value={fee.description}
                          status={fee.description.length > 0 ? undefined : "error"}
                          statusMessage={fee.description.length > 0 ? undefined : "Description must be provided"}
                          onChange={(e) => {
                            const newViolation = [...currentRule.output];
                            newViolation[index].description = e.currentTarget.value;
                            setCurrentRule({
                              ...currentRule,
                              output: newViolation
                            })
                          }}
                          autoGrow={true}
                        ></InputArea>
                      </FormField>
                    </Box> : <></>}
                  </Card.Content>
                </Card>
              </Cell>
            ))}
          </Layout>
          <Box gap="SP2">
            <Button onClick={() => {
              const newFees = [...currentRule.output || []];
              if (currentRule.type === 'shipping-rates') {
                newFees.push({
                  code: "EXPRESS_DELIVERY",
                  title: "Express Delivery",
                  logistics: {
                    deliveryTime: "Same Day Guaranteed",
                    instructions: "We will deliver your order today, guaranteed.",
                    // pickupDetails: {
                    //   address: {
                    //     country: "US",
                    //     subdivision: "NY",
                    //     city: "Test City",
                    //     postalCode: "10000",
                    //     addressLine: "Demo Address 1",
                    //     addressLine2: "Demo Address 2"
                    //   },
                    //   pickupMethod: "STORE_PICKUP" // "PICKUP_POINT"
                    // }
                  },
                  cost: {
                    price: "5.00",
                    currency: "USD",
                    // additionalCharges: [{
                    //   type: "HANDLING_FEE",
                    //   details: "Handling fee",
                    //   price: "5.00"
                    // }]
                  }
                });
              }
              if (currentRule.type === 'additional-fees') {
                newFees.push({
                  code: "",
                  name: "",
                  price: "0.00",
                  taxDetails: {
                    taxable: false
                    // taxGroupId: "1"
                  }
                });
              }
              if (currentRule.type === 'validations') {
                newFees.push({
                  "severity": "ERROR",
                  "target": {
                    "other": {
                      "name": "OTHER_DEFAULT"
                    }
                  },
                  "description": "Can't apply coupon because minimum cart value has not been reached.",
                });
              }
              setCurrentRule({
                ...currentRule,
                output: newFees
              })
            }}
              skin="inverted"
              prefixIcon={<Icons.Add />}
            >Add fees</Button>
            <Button
              prefixIcon={<Icons.Confirm />}
              disabled={(currentRule.output || []).length === 0 || isSaving}
              onClick={() => {
                createRule();
              }}
            >Set Rule Live</Button>
          </Box>
        </Box>
      </Card.Content>
    </Card>
  }

  if (!instanceData && !isSaving) {
    return <Loader size="small" status="error" statusMessage="Please reinstall this app if error persist." />;
  }

  class StoresAppMissing extends React.Component {
    render() {
      return (
        <AnnouncementModalLayout
          title="Install Wix Stores to start using Logic: Advanced Logic for eCom"
          primaryButtonText="Install now"
          primaryButtonOnClick={() => { window.open(`https://manage.wix.com/dashboard/${(instanceData as any)['site']['siteId']}/app-market/web-solution/wix-stores`) }}
        >
          <Text>
            Professional eCommerce platform to sell online. It's easy to install and use.
          </Text>
        </AnnouncementModalLayout>
      );
    }
  }

  const upgradeUrl = `https://www.wix.com/apps/upgrade/${"e79759eb-0d13-4d31-8171-f97c3d61c638"}?appInstanceId=${(instanceData as any)['instance']['instanceId']}`

  return (
    <WixDesignSystemProvider features={{ newColorsBranding: true }}>
      <Modal
        isOpen={isUpgradeModalOpen}
        shouldCloseOnOverlayClick={true}
        onRequestClose={() => setIsUpgradeModalOpen(false)}
      >
        <AnnouncementModalLayout
          theme="premium"
          title="Start building business logic today"
          primaryButtonText={!isPremiumUpgradeDisallowed ? "Upgrade" : "Upgrading isn’t available"}
          // linkText="Learn More"
          primaryButtonOnClick={() => {
            if (!isPremiumUpgradeDisallowed) {
              window.open(upgradeUrl)
            }
          }}
          // linkOnClick={() => {
          //   window.open(`https://support.certifiedcode.us/en/articles/7851398-how-chat-gpt-based-conversation-s-plans-work`)
          // }}
          onCloseButtonClick={() => setIsUpgradeModalOpen(false)}
        >
          <Text>
            Upgrade "Logic: Advanced Logic for eCom" to create than one rule of each type.
          </Text>
        </AnnouncementModalLayout>
      </Modal>
      <Modal
        isOpen={(instanceData as any)['site'] ? (instanceData as any)['site']['installedWixApps'].indexOf('stores') === -1 : false}
        shouldCloseOnOverlayClick={false}
      >
        {<StoresAppMissing></StoresAppMissing>}
      </Modal>
      {/* <Notification theme="premium" show={isUpgradeBannerOpen} type={'sticky'}>
          <Notification.TextLabel>
              {`Upgrade to unlock "${CONTENT[index]}"`}
          </Notification.TextLabel>
          <Notification.ActionButton
              onClick={() => {
                  window.open(
                    upgradeUrl,
                      '_blank'
                  );
              }}
          >
              Upgrade
          </Notification.ActionButton>
          <Notification.CloseButton onClick={() => setIsUpgradeBannerOpen(false)} />
      </Notification> */}
      <Notification show={notification.show || false} theme={notification.theme || "standard"}>
        <Notification.TextLabel>
          {notification.message || ""}
        </Notification.TextLabel>
        <Notification.CloseButton />
      </Notification>
      <Page className={`${isUpgradeBannerOpen ? "App-header-notification" : ""}`}>
        <Page.Header
          title="Logic: Advanced Logic for eCom"
          subtitle="Logic is a powerful tool for creating advanced logic for your Wix Ecom store, such as custom shipping options and additional fees."
          actionsBar={
            <Box align="center" gap="SP2">
              <Button skin="light" prefixIcon={<Icons.Help />} as='a' href="https://support.certifiedcode.us/en/articles/8060633-getting-started-with-logic-advanced-logic-for-ecom" target="_blank" >
                Help Centre
              </Button>
              <Button skin="premium" prefixIcon={<Icons.PremiumFilled />} disabled={isUpgraded} as='a' target='_blank' href={
                `https://www.wix.com/apps/upgrade/${"e79759eb-0d13-4d31-8171-f97c3d61c638"}?appInstanceId=${(instanceData as any)['instance']['instanceId']}`
              }>
                {isUpgraded ? "Upgraded" : "Upgrade"}
              </Button>
            </Box>
          }
          showBackButton={setupState > 0}
          onBackClicked={() => {
            if (!isSaving)
              setSetupState(setupState - 1);
          }}
        />
        <Page.Content>
          <Layout>
            <Cell span={12}> {setupState === 0 ? RuleList() : <></>}</Cell>
            <Cell span={12}>{setupState === 1 ? RuleCreationStep1() : <></>}</Cell>
            <Cell span={12}>{(setupState === 1 && currentRule.type
            ) ? RuleCreationStep2() : <></>}</Cell>
          </Layout>
          <Box height={"30px"}></Box>
          <Text skin="disabled" size="tiny">Need help setting things up? <a onClick={() => {
            window.Intercom('showNewMessage');
          }}>Contact Support</a></Text>
        </Page.Content>
      </Page>
    </WixDesignSystemProvider>
  );
}

export default App;
